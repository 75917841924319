.header {
  .navbar {
    align-items: center;

    background: #FFFFFF !important;

    padding: 10px 20px;
    min-height: 60px;

    height: 70px;

    .nav-item {
      font-size: 18px;
      .nav-link {
        svg {
          margin-right: 0.2vw;
        }
      }
      .dropdown-toggle {
        img {
          margin-right: 0.5vw;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.menu-item-doctor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
}

.menu-item-patient {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
}

.menu-item-partner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
}

.image-profile {
  border-radius: 50%;
  width: 38px !important;
  height: 38px !important;
  margin-right: 0 !important
}

.drop-no-arrow {
  a::after {
    display: none !important;
  }
}

.drop-menu-item {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
