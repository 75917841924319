.doctor-patient-history {
  h4 {
    color: lightslategray;
  }

  .card {
    background: #FFFFFF;
    box-shadow: 0px 3px 4px 2px rgba(153, 164, 177, 0.25);
    border-radius: 8px;
  }

  .card-title {
    padding-top: 15px;
  }

  .card-title, .card-sub-title {
    padding-left: 15px ;
  }

  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;

    .section-title {
      font-weight: 600;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }

    img {
      height: 100px;
      width: 85px;
      border-radius: 50%;
    }

    &.fixed-height {
      height: 25vh;
    }

    .information {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #000000;
    }
  }
}

.show-more {
  color: #fff;
}
