.admins-table {
  width: 100%;

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #E5E8EB;
  }

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #324963;

  .alert-text {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #66768A;
  }
}
