.patient-card {
  font-family: Rubik;
  border: 1px solid lightgray;
  padding: 3vh 2vh 3vh 2vh;

  background: #ffffff;
  box-shadow: 0px 3px 4px 2px rgba(153, 164, 177, 0.25);
  border-radius: 8px;

  img {
    position: absolute;
    width: 68px;
    height: 68px;
    left: 24px;
    top: 25px;
  }

  .text {
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    color: #001c3d;
  }

  .patient-name {
    font-weight: 600;
  }

  .patient-info-title {
    color: gray;

    .patient-info-value {
      font-weight: 600;
    }
  }

  .divider {
    margin-top: 2vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid lightgray;
  }

  // .min-divider {
  //   border: 1px solid #E5E8EB;
  //   position: absolute;
  //   width: 73px;
  //   height: 0px;
  //   left: 58px;
  //   top: 174px;
  // }

  .text-card {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #001c3d;
  }

  .patient-card-button {
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #008171;
  }
}
