.create-user {
  background: #ffffff;
  box-shadow: 0px 3px 4px 2px rgba(153, 164, 177, 0.25);
  border-radius: 8px;
  padding: 10px 40px 10px 40px;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}

.form-check-label {
  span {
    padding-left: 3px;
    vertical-align: middle;
  }
}

input[type='radio']:hover {
  box-shadow: 0 0 5px 0px orange inset;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: #008171;
}
