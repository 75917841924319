.textarea {
  background: #FFFFFF;
  border: 1px solid #CCE5E2;
  box-sizing: border-box;
  border-radius: 8px;
}

.textarea::-webkit-input-placeholder {
  color: #B2BAC4;
}

.textarea:focus-visible {
  box-shadow: none;
  border-color: #CCE5E2;
}

.textarea:hover {
  background: #FFFFFF;
  border: 1px solid #CCE5E2;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
}

.textarea:focus {
  background: #FFFFFF;
  border: 1px solid #CCE5E2;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
}

.textarea:valid:not(:placeholder-shown) {
  color: #002020;
  font-weight: 500;
}

.textarea:valid:placeholder-shown {
  color: #B2BAC4;
}

.error-input-class {
  background: #F9BEB9;
  border: 1px solid #F05246;
}

.error-input-class:hover {
  background: #F9BEB9;
  border: 1px solid #F05246;
  box-shadow: none;
}

.error-input-class:focus {
  background: #F9BEB9;
  border: 1px solid #F05246;
}

.error-input-class:focus-visible {
  box-shadow: none;
  border-color: none;
}

.error-input-class::-webkit-input-placeholder {
  color: white;
}

.label-class {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #002621;
}

.error-label-class {
  color: #F05246;
}

.input-check {
  border: 1px solid #008171;
  border-radius: 4px;
  background: #E5F5F5;
  margin-top: 7px;
  left: 36px;
}

.input-check-label {
  margin-left: 20px;
}

.error-message {
  margin-left: 10px;
  color: #F05246;
  font-weight: 500;
  font-size: 14px;
}
