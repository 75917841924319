.doctor-attendance-info {
  h4 {
    color: lightslategray;
    margin-top: 1.9rem;
  }

  .mt-6 {
    margin-top: 1.9rem !important;
  }

  .label-attendance {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #008171;
  }

  .bordless-container {
    .label-pacient {
      font-family: Rubik;
      font-weight: 700;
      color: #008171;
    }

    .divider-div {
      border: 1px solid #e5e8eb;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }

    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      color: #001c3d;
    }

    .span-pacient {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }
  }

  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;

    .section-title {
      font-weight: 600;
      color: #008171;
    }

    .section-title-font-larger {
      font-size: larger;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }

    &.fixed-height {
      height: 40vh;
    }

    .btn-group {
      width: 100%;
    }

    .dropdown-menu {
      width: 100%;
    }

    .label-container {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      color: #001c3d;
    }

    .button-container {
      background: #4c6077;
      box-shadow: 0px 2px 3px rgba(0, 28, 61, 0.15);
    }

    .button-container:focus {
      background: #4c6077;
    }

    .button-container:hover {
      background-color: #ffffff;
      border-color: #4c6077;
      border-radius: 6px;

      text {
        color: #4c6077;
      }
    }

    .button-container:active {
      background-color: #4c6077 !important;
      border-color: #4c6077 !important;

      text {
        color: #ffffff;
      }
    }

    .select {
      div:first-child {
        box-sizing: border-box;
        border-radius: 26px;
        font-family: Rubik;
        font-size: 14px;
        line-height: 19px;
        color: #002020;
        font-weight: bold;
      }
    }

    .input {
      background: #ffffff;
      border: 1px solid #cce5e2;
      box-sizing: border-box;
      border-radius: 26px;
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.divider-div {
  margin-top: 0.4vh;
  border-bottom: 2px solid rgb(231, 226, 226);
}

.ql-container {
  min-height: 200px;
}

.radio-label {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.radio-input {
  input {
    margin-top: 7px;
  }
}
