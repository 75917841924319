.indicator{
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  height: 15px;
  margin-bottom: 0.3rem;
}

.contentArea {
  width: 100%;
  background-color: #fff;
  padding: 40px;

  &.shadowed {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.31);
  }

  &.roundedBorder {
    border-radius: 5px;
  }

  &.sizeSmall {
    width: 500px;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 270px;
  padding: 1.25rem;
}

.green {
  background-color: #a2bb82;
}

.yellow {
  background-color: #fbf38f;
}

.orange {
  background-color: #fad390;
}

.redish {
  background-color: #e6828e;
}

.red {
  background-color: #eb3d51;
}
