.doctor-attendance-info {
  h4 {
    color: lightslategray;
    margin-top: 1.9rem;
  }

  .mt-6 {
    margin-top: 1.9rem !important;
  }

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #001c3d;
    text-align: center;
  }

  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;

    .section-title {
      font-weight: 600;
    }

    .section-title-font-larger {
      font-size: larger;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }

    img {
      height: 100px;
      width: 85px;
      border-radius: 50%;
    }

    &.fixed-height {
      height: 40vh;
    }

    .btn-group {
      width: 100%;
    }

    .dropdown-menu {
      width: 100%;
    }
  }
}

.divider-div {
  margin-top: 0.4vh;
  border-bottom: 2px solid rgb(231, 226, 226);
}

.ql-container {
  min-height: 200px;
}
