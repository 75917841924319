.success-registration {

  .title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 80px;
    text-transform: uppercase;
    color: #008171;
  }

  .text {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 80px;
    color: #000000;
  }

}
