.chatBot {
  .options {
    margin-top: 1rem;

    button {
      margin-bottom: 0.5rem;
      white-space: normal;
      height: 100%;

      &:last-of-type {
        margin-bottom: unset;
      }
    }
  }
}

.contentArea {
  position: relative;
}

.sairButton {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
}

.doctor-attendance-info {
  h4 {
    color: lightslategray;
    margin-top: 1.9rem;
  }

  .mt-6 {
    margin-top: 1.9rem !important;
  }

  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;

    .section-title {
      font-weight: 600;
    }

    .section-title-font-larger {
      font-size: larger;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }

    img {
      height: 100px;
      width: 85px;
      border-radius: 50%;
    }

    &.fixed-height {
      height: 40vh;
    }

    .btn-group {
      width: 100%;
    }

    .dropdown-menu {
      width: 100%;
    }
  }
}

.divider-div {
  margin-top: 0.4vh;
  border-bottom: 2px solid rgb(231, 226, 226);
}

.ql-container {
  min-height: 200px;
}

.question-button {
  button:focus{
    background-color: #4abbbb !important;
    border-color: #4abbbb !important;
  }
}

