.doctors-patients {
  h4 {
    color: lightslategray;
  }

  .mt-6 {
    margin-top: 1.9rem !important;
  }

  .doctor-patients-cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .doctor-patients-cards {
      width: 29%;
      margin: 10px;
    }
  }
}
