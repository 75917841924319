.return-link {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #008171;
}

.return-link:hover {
  color: #008171;
}
