.select-multi {
    > div {
    background: #FFFFFF;
    border: 1px solid #CCE5E2;
    box-sizing: border-box;
    border-radius: 26px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }

  input {
    font-family: Rubik;
  }
}

.select-multi::-webkit-input-placeholder {
  color: #B2BAC4;
}

.select-multi:focus-visible {
  > div {
    box-shadow: none;
    border-color: #CCE5E2;
  }
}

.select-multi:hover {
  > div {
    background: #FFFFFF;
    border: 1px solid #CCE5E2;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
    border-radius: 26px;
  }
}

.select-multi:focus {
  > div {
    background: #FFFFFF;
    border: 1px solid #CCE5E2;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
    border-radius: 26px;
  }
}

.select-multi:valid:not(:placeholder-shown) {
  > div {
    color: #002020;
    font-weight: bold;
  }
}

.select-multi:valid:placeholder-shown {
  > div {
    color: #B2BAC4;
  }
}

.error-input-class {
  background: #F9BEB9;
  border: 1px solid #F05246;
}

.error-input-class:hover {
  background: #F9BEB9;
  border: 1px solid #F05246;
  box-shadow: none;
}

.error-input-class:focus {
  background: #F9BEB9;
  border: 1px solid #F05246;
}

.error-input-class:focus-visible {
  box-shadow: none;
  border-color: none;
}

.error-input-class::-webkit-input-placeholder {
  color: white;
}
