* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

#root,
body,
.container {
  position: relative;
  min-height: 100%;
}

#root,
body,
input,
button {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
}
