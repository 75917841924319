.view-profile-page {
  font-family: Rubik;
  font-style: normal;

  h4 {
    color: gray;
  }

  img {
    border-radius: 50%;
  }

  .bordered-container {
    border: 1px solid lightgray;
    padding: 20px;
    box-shadow: 0px 3px 4px 2px rgba(153, 164, 177, 0.25);
    border-radius: 8px;

    & + .bordered-container {
      margin-top: 4vh;
    }

    .section-title {
      font-weight: 600;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }
  }
}
