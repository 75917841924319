.doctor-dashboard {
  .divider {
    margin-top: 0.4vh;
    border-bottom: 2px solid rgb(231, 226, 226);
  }

  .last-patients-text {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #001C3D;
  }
}
