.attendance-cards-table {
  font-family: Rubik;
    .results-text {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      color: #001C3D;
    }

    .page-link {
      border: none;
      background: linear-gradient(0deg, rgba(229, 232, 235, 0.15), rgba(229, 232, 235, 0.15)), #FFFFFF;
      color: #000000;
      border: 1px solid #008171;
      box-sizing: border-box;
    }

    .page-link:focus {
      box-shadow: none !important;
      background: #E5F2F0;
      color: #008171;
    }

    .btn-secondary:focus, .btn-secondary:focus {
      box-shadow: none;
    }
}

.links-text {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #001C3D;
}
