.input-class {
  background: #ffffff;
  border: 1px solid #cce5e2;
  box-sizing: border-box;
  border-radius: 26px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.input-class::-webkit-input-placeholder {
  color: #b2bac4;
}

.input-class:focus-visible {
  box-shadow: none;
  border-color: #cce5e2;
}

.input-class:hover {
  background: #ffffff;
  border: 1px solid #cce5e2;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
  border-radius: 26px;
}

.input-class:focus {
  background: #ffffff;
  border: 1px solid #cce5e2;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
  border-radius: 26px;
}

.input-class:valid:not(:placeholder-shown) {
  color: #002020;
  font-weight: 500;
}

.input-class:valid:placeholder-shown {
  color: #b2bac4;
}

.error-input-class {
  background: #f9beb9;
  border: 1px solid #f05246;
}

.error-input-class:hover {
  background: #f9beb9;
  border: 1px solid #f05246;
  box-shadow: none;
}

.error-input-class:focus {
  background: #f9beb9;
  border: 1px solid #f05246;
}

.error-input-class:focus-visible {
  box-shadow: none;
  border-color: none;
}

.error-input-class::-webkit-input-placeholder {
  color: white;
}

.label-class {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #002621;
}

.error-label-class {
  color: #f05246;
}

// .input-check {
//   border: 1px solid #008171;
//   border-radius: 4px;
//   background: #e5f5f5;
//   margin-top: 7px;
//   left: 36px;
// }

// .input-check-label {
//   margin-left: 20px;
// }

.error-message {
  margin-left: 10px;
  color: #f05246;
  font-weight: 500;
  font-size: 14px;
}
