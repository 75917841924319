.patient-info {
  .bordless-container {
    .label-pacient {
      font-family: Rubik;
      font-weight: 700;
      color: #008171;
    }

    .divider-div {
      border: 1px solid #e5e8eb;
    }

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }

    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #001c3d;
    }

    .span-pacient {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }
  }
}
