.view-profile-page {
  h4 {
    color: gray;
  }

  img {
    border-radius: 50%;
  }

  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;

    & + .bordered-container {
      margin-top: 4vh;
    }

    .section-title {
      font-weight: 600;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 5px 0px gray inset;
  }

  input[type='checkbox']:hover {
    box-shadow: 0 0 5px 0px orange inset;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type='checkbox']:checked:before {
    background: #008171;
  }

  .input-check-label {
    margin-left: 26px;
    margin-top: 3px;
  }

  .input-form-label {
    display: flex;

    label {
      padding-left: 3px;
      padding-top: 5px;
    }
  }

  .input-check-label {
    margin-left: 5px;
    margin-top: 3px;
  }
}
