.secondary {
  background: #ffffff;
  border: 2px solid #008171;
  box-shadow: 0px 2px 3px rgba(0, 28, 61, 0.15);
  border-radius: 6px;
  width: 100%;

  text {
    color: #008171;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.secondary:focus {
  background: #ffffff;
}

.secondary:hover {
  background-color: #329a8d;
  border-color: #329a8d;
  border-radius: 6px;

  text {
    color: white;
  }
}

.secondary:active {
  background-color: white !important;
  border-color: #004038 !important;

  text {
    color: #004038;
  }
}

.secondary:focus {
  box-shadow: none !important;
}

.secondary:disabled {
  background: #ffffff;
  border: 2px solid #b2bac4;
  border-radius: 6px;

  text {
    color: #b2bac4;
  }
}

.primary {
  background: #00a0a0;
  box-shadow: 0px 2px 3px rgba(0, 28, 61, 0.15);
  border-radius: 6px;
  width: 100%;

  text {
    color: white;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.primary:focus {
  background: #00a0a0;
}

.primary:hover {
  background-color: white;
  border-color: #329a8d;
  border-radius: 6px;

  text {
    color: #329a8d;
  }
}

.landing {
  background: #00675A;
  box-shadow: 0px 2px 3px rgba(0, 28, 61, 0.15);
  border-radius: 6px;
  width: 100%;

  text {
    color: white;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.landing:focus {
  background: #00675A;
}

.landing:hover {
  background-color: white;
  border-color: #00675A;
  border-radius: 6px;

  text {
    color: #00675A;
  }
}

.primary:active {
  background-color: #004038 !important;
  border-color: #004038 !important;

  text {
    color: white;
  }
}

.primary:focus {
  box-shadow: none !important;
}

.primary:disabled {
  background: #b2bac4;
  border: 2px solid #b2bac4;
  border-radius: 6px;

  text {
    color: white;
  }
}

.default {
  background: #4c6077;
  box-shadow: 0px 2px 3px rgba(0, 28, 61, 0.15);
  border-radius: 6px;
  width: 100%;

  text {
    color: white;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.default:focus {
  background: #4c6077;
}

.default:hover {
  background-color: #ffffff;
  border-color: #4c6077;
  border-radius: 6px;

  text {
    color: #4c6077;
  }
}

.default:active {
  background-color: #4c6077 !important;
  border-color: #4c6077 !important;

  text {
    color: #ffffff;
  }
}
