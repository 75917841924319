.accordeon {
  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 3px 4px 2px rgba(153, 164, 177, 0.25);
    padding: 20px;

    .accordeon-section-title {
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      color: #001c3d;
    }
  }

  .divider {
    margin-top: 0.4vh;
    border-bottom: 2px solid rgb(231, 226, 226);
  }
}
