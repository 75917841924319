.generic-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 38px;
  width: 100%;
  background-color: #002621;

  p {
    color: white;
    font-size: 13px;

    margin-bottom: 6px;
    margin-top: 8px;
    margin-right: 70px;

    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }
}
