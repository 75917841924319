.schedules-table {
  width: 100%;

  .page-item.active .page-link {
    background-color: #00a0a0;
    border-color: #00a0a0;
  }

  .page-link {
    color: #00a0a0;
  }

  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #324963;

  .alert-text {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #66768a;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 5px 0px gray inset;
  }

  input[type='checkbox']:hover {
    box-shadow: 0 0 5px 0px orange inset;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type='checkbox']:checked:before {
    background: #008171;
  }

  .input-check-label {
    margin-left: 26px;
    margin-top: 3px;
  }

  .input-form-label {
    display: flex;

    label {
      padding-left: 3px;
      padding-top: 5px;
    }
  }

  .input-check-label {
    margin-left: 5px;
    margin-top: 3px;
  }
}
