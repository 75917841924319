.adminstrative-new-attendance {
  h4 {
    color: lightslategray;
  }

  .mt-6 {
    margin-top: 1.9rem !important;
  }

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #001c3d;
  }

  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;

    .section-title {
      font-weight: 600;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }
  }
}
