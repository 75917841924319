.doctor-dashboard-card {
  font-family: Rubik;
  .bordered-container-card {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px 2px rgba(153, 164, 177, 0.25);
    border-radius: 8px;
  }

  .span-date {
    font-size: 10px;
  }

  .span-size {
    font-size: small;
  }

  .patient {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #001C3D;
  }

  .divider {
    margin-top: 2vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid lightgray;
  }

  .redirect-link {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #008171;
    cursor: pointer;
  }
}
