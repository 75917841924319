.ampal-attendance-info {
  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 5px 0px gray inset;
  }

  input[type='checkbox']:hover {
    box-shadow: 0 0 5px 0px orange inset;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type='checkbox']:checked:before {
    background: #008171;
  }

  .input-check-label {
    margin-left: 26px;
    margin-top: 3px;
  }

  .input-form-label {
    display: flex;

    label {
      padding-left: 3px;
      padding-top: 5px;
    }
  }

  .input-check-label {
    margin-left: 5px;
    margin-top: 3px;
  }

  h4 {
    color: lightslategray;
    margin-top: 1.9rem;
  }

  .mt-6 {
    margin-top: 1.9rem !important;
  }

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    color: #001c3d;
    text-align: center;
  }

  .bordered-container {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;

    .section-title {
      font-weight: 600;
    }

    .section-title-font-larger {
      font-size: larger;
    }

    .divider {
      margin-top: 0.4vh;
      border-bottom: 2px solid rgb(231, 226, 226);
    }

    img {
      height: 100px;
      width: 85px;
      border-radius: 50%;
    }

    &.fixed-height {
      height: 40vh;
    }

    .btn-group {
      width: 100%;
    }

    .dropdown-menu {
      width: 100%;
    }
  }
}

.divider-div {
  margin-top: 0.4vh;
  border-bottom: 2px solid rgb(231, 226, 226);
}

.ql-container {
  min-height: 200px;
}
