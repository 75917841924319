.datepicker {
  background: #FFFFFF;
  border-radius: 26px !important;
  border: 1px solid #CCE5E2;
  box-sizing: border-box;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.popover {
  max-width: 410px !important;
}

.bg-primary {
  background-color: #008171 !important;
}

.datepicker::-webkit-input-placeholder {
  color: #B2BAC4;
}

.datepicker:focus-visible {
  box-shadow: none;
  border-color: #CCE5E2;
}

.datepicker:hover {
  background: #FFFFFF;
  border: 1px solid #CCE5E2;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
  border-radius: 26px;
}

.datepicker:focus {
  background: #FFFFFF;
  border: 1px solid #CCE5E2;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px 3px rgba(153, 164, 177, 0.25);
  border-radius: 26px;
}

.datepicker:valid:not(:placeholder-shown) {
  color: #002020;
  font-weight: 500;
}

.datepicker:valid:placeholder-shown {
  color: #B2BAC4;
}


.error-input-class {
  background: #F9BEB9;
  border: 1px solid #F05246;
}

.error-input-class:hover {
  background: #F9BEB9;
  border: 1px solid #F05246;
  box-shadow: none;
}

.error-input-class:focus {
  background: #F9BEB9;
  border: 1px solid #F05246;
}

.error-input-class:focus-visible {
  box-shadow: none;
  border-color: none;
}

.error-input-class::-webkit-input-placeholder {
  color: white;
}
