.doctors-table {
  width: 100%;
  .page-item.active .page-link {
    background-color: #00a0a0;
    border-color: #00a0a0;
  }
  .page-link {
    color: #00a0a0;
  }
}
