.login-page {
  height: 100vh;
  width: 100%;
  font-family: Rubik;

  .login-button {
    div:first-of-type {
      margin-right: 5px;
    }
  }

  .container-rigth {
    background: url('../../resources/images/login.svg');
  }

  login-image {
    padding: 0;
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
}

  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 5px 0px gray inset;
  }

  input[type='checkbox']:hover {
    box-shadow: 0 0 5px 0px orange inset;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type='checkbox']:checked:before {
    background: #008171;
  }

  .input-check-label {
    margin-left: 26px;
    margin-top: 3px;
  }

  .input-form-label {
    display: flex;

    label {
      padding-left: 3px;
      padding-top: 5px;
    }
  }

  .container-fluid {
    height: 100%;

    .row {
      height: 100%;
    }

    .bg-image {
      background-image: url('https://source.unsplash.com/WEQbe2jBg40/600x1200');
      background-size: cover;
      background-position: center;
    }

    .w-150 {
      width: 150px;
    }

    .container {
      height: 100%;

      .row {
        height: auto;
      }

      .col {
        height: auto;
      }

      .form-check-input {
        margin-left: 5px;
        margin-top: 5px;
      }

      .copyright-row {
        position: fixed;
        right: 10vw;
        bottom: 20vh;
        top: 80vh;

        .copyright-text {
          color: lightgray;

          font-size: 15px;
        }
      }
    }
  }
}

.message-presentation {
  font-family: Rubik;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
}

.sub-message-presentation {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}

.text-register {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}

.image-top {
  position: absolute;
  left: 720px;
  top: 4%;
}

.image-middle {
  position: absolute;
  left: 683px;
  top: 35%;
}

.image-end {
  position: absolute;
  left: 720px;
  top: 66%;
}
